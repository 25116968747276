import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHourglass, faTimes, faList } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/AdminCards.css';
import { useSession } from "./../Common/SessionContext";

const AdminCards = ({ onCardClick }) => {
  const serverUrl = process.env.REACT_APP_API_URL;
  const { sessionData } = useSession();
  const [counts, setCounts] = useState({
    total: 0,
    pending: 0,
    rejected: 0,
    approved: 0,
    verified: 0,
  });

  const [avgTimeDifferences, setAvgTimeDifferences] = useState({
    pending: '',
    rejected: '',
    approved: '',
    verified: '',
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch counts
        const countResponse = await axios.get(`${serverUrl}/api/count`);
        setCounts(countResponse.data);

        // Fetch average time differences
        const requestStatuses = ['pending', 'rejected', 'approved', 'verified'];
        const avgTimeDiffs = {};

        for (const status of requestStatuses) {
          const response = await axios.get(`${serverUrl}/api/request?status=${status}`);
          avgTimeDiffs[status] = response.data.avgTimeDifference;
        }

        setAvgTimeDifferences(avgTimeDiffs);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    fetchData();
  }, []);

  const renderCard = (title, icon, count, color, status, avgTimeDiff) => {
    return (
      <div className="col-lg-3 col-md-6 mb-4" onClick={() => onCardClick(status)} style={{ cursor: 'pointer' }}>
        <div className={`card text-white h-100 ${color}`}>
          <div className="card-body d-flex justify-content-between align-items-center">
            <div>
              <h5 className="card-title dash-title text-white">{title}</h5>
              <h3 className='fw-bold fs-2'>{count}</h3>
              {avgTimeDiff && <p className="avg-time-difference">{avgTimeDiff}</p>}
            </div>
            <FontAwesomeIcon icon={icon} className="dash-icon" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container pt-2">
      <h3>{capitalizeFirstLetter(sessionData.userRole)} Dashboard</h3>
      <hr />
      <div className="row">
        {renderCard('Total Requests', faList, counts.total, 'total-card', 'total', '')}
        {renderCard('Pending & Verified', faHourglass, `${counts.pending} | ${counts.verified}`, 'pending-card', 'pending_verified', `${avgTimeDifferences.pending} | ${avgTimeDifferences.verified}`)}
        {renderCard('Approved Requests', faCheck, counts.approved, 'approved-card', 'approved', avgTimeDifferences.approved)}
        {renderCard('Rejected Requests', faTimes, counts.rejected, 'rejected-card', 'rejected', avgTimeDifferences.rejected)}
      </div>
    </div>
  );
};

export default AdminCards;
