import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  faHome,
  faList,
  faUser,
  faUsers,
  faChevronLeft,
  faSignOut,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./../../styles/Sidebar.css";
import { useSession } from "./../Common/SessionContext";

const Sidebar = () => {
  const { sessionData } = useSession();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const userName = localStorage.getItem('userName')

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleLogout = async () => {
    localStorage.removeItem('branchCode');
    localStorage.removeItem('branchName');
    localStorage.removeItem('roleName');
    try {
      const response = await axios.post('https://navanpiserver.wellnessforever.co.in/api/auth/logout');
      if (response.status === 200) {
        navigate('/');
      } else {
        setError('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      setError('An error occurred during logout. Please try again.');
    }
  };

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <button onClick={toggleSidebar} className="toggle-button">
        <FontAwesomeIcon icon={isCollapsed ? faChevronRight : faChevronLeft} />
        {!isCollapsed && " Admin Panel"}
      </button>
      <li className="text-light user-info" style={{ paddingBottom: '0', marginBottom: '0' }}>
        <p style={{ marginBottom: '0'}}>{userName}</p>
      </li>
      <hr className="text-light" />
      <ul>

        <li className="text-light">
          <FontAwesomeIcon icon={faList} className="fa-icon" />
          <Link to={`/${sessionData.userRole}/list`}>List</Link>
        </li>

        {sessionData.userRole === "superadmin" && (
          <>
            <li className="text-light">
              <FontAwesomeIcon icon={faUsers} className="fa-icon" />
              <Link to={`/${sessionData.userRole}/userlist`}>User List</Link>
            </li>
          </>
        )}



        <li
          className="text-light"
          style={{ position: "fixed", bottom: "20px", marginLeft: "8px" }}
        >
          <FontAwesomeIcon icon={faSignOut} className="fa-icon" />
          <Link to={`/logout`}>Log Out</Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
