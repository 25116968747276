import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import axios from 'axios';

const Autocomplete = ({ url, value, onChange, label, id, isCategory, isHead, isClass, isHSNSAC, isBrandName, isMfgName }) => {
    const [options, setOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (value) {
            const defaultOption = isClass
                ? {
                    value: value,
                    label: value.l3_name
                }
                : {
                    value: value,
                    label: value
                };
            setOptions([defaultOption]);
        }
    }, [value, isClass]);

    const fetchData = async (query) => {
        setIsLoading(true);
        try {
            const response = await axios.get(url, {
                params: { q: query }
            });
            const fetchedOptions = response.data.map(item => {
                if (isHSNSAC) {
                    return {
                        value: item.code,
                        label: item.code
                    };
                } else if (isClass) {
                    return {
                        value: item,
                        label: item.l3_name
                    };
                } else {
                    return {
                        value: item.code + '|' + item.name,
                        label: item.name
                    };
                }
            });
            setOptions(fetchedOptions);
        } catch (error) {
            console.error(`Error fetching data for ${id}:`, error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (inputValue) => {
        if (inputValue.length >= 1) {
            fetchData(inputValue);
        } else {
            setOptions([]);
        }
    };

    const handleSelectChange = (selectedOption) => {
        if (isClass) {
            onChange(selectedOption ? selectedOption.value : null);
        } else if (selectedOption && selectedOption.isNew) {
            const formattedValue = `######|${selectedOption.label.replace(/\s*\(NEW\)\s*$/, '')}`;
            onChange({ target: { id, value: formattedValue } });
        } else {
            onChange({ target: { id, value: selectedOption ? selectedOption.value : '' } });
        }
    };

    const formatOptionLabel = ({ label }, { context }) => {
        if (!label) {
            return '';
        }
        if (context === 'menu') {
            return label;
        }
        return label.replace(/\s*\(NEW\)\s*$/, '');
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.isNew ? 'lightgreen' : provided.backgroundColor,
            color: state.data.isNew ? 'black' : provided.color,
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.isNew ? 'lightgreen' : provided.backgroundColor,
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: state.data.isNew ? 'black' : provided.color,
        }),
        singleValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.isNew ? 'lightgreen' : provided.backgroundColor,
            color: state.data.isNew ? 'black' : provided.color,
        }),
    };

    return (
        <div className="form-group">
            <label htmlFor={id}>{label}<span> *</span></label>
            {(isBrandName || isMfgName) ? (
                <CreatableSelect
                    id={id}
                    options={options}
                    isLoading={isLoading}
                    onInputChange={handleInputChange}
                    onChange={handleSelectChange}
                    value={options.find(option => option.value === value)}
                    placeholder={`Select ${label}`}
                    isClearable
                    formatCreateLabel={(inputValue) => `${inputValue.toUpperCase()} (NEW)`}
                    getNewOptionData={(inputValue, optionLabel) => ({
                        value: inputValue.toUpperCase(),
                        label: `${inputValue.toUpperCase()} (NEW)`,
                        isNew: true
                    })}
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                />
            ) : (
                <Select
                    id={id}
                    options={options}
                    isLoading={isLoading}
                    onInputChange={handleInputChange}
                    onChange={handleSelectChange}
                    value={options.find(option => option.value === value)}
                    placeholder={`Select ${label}`}
                    isClearable
                    formatOptionLabel={formatOptionLabel}
                    styles={customStyles}
                />
            )}
        </div>
    );
};

export default Autocomplete;
